var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-booking-table"},[_c('h3',{staticClass:"py-3"},[_vm._v("Rejected")]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.bookingTableColumns,"rows":_vm.bookingTableData,"totalRows":_vm.bookingTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toBookingDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"row justify-content-center"},[_c('button',{staticClass:"col-12 btn main mb-2",on:{"click":function($event){return _vm.viewReason(props.row.id)}}},[_vm._v(" View Reason ")]),_c('button',{staticClass:"col-12 btn bordered danger",on:{"click":function($event){return _vm.openCancelModal(props.row.id)}}},[_c('i',{staticClass:"fas fa-times mr-1"}),_vm._v(" Cancel ")])])]):(props.column.field == 'project')?_c('span',[_c('div',{staticClass:"row mt-1 project-detail"},[_c('div',{staticClass:"col-4 mb-2 pr-2"},[_c('img',{staticClass:"main-photo",attrs:{"src":_vm.$getFileURL(props.row.project.mainPhoto[0]),"alt":props.row.project.name,"loading":"lazy"},on:{"error":_vm.$setAltPhoto}})]),_c('div',{staticClass:"col-8"},[_c('p',{staticClass:"title mb-1"},[_c('span',{staticClass:"plot mr-1"},[_vm._v(" "+_vm._s(props.row.projectUnit.plot)+" ")]),_vm._v(" "+_vm._s(props.row.projectUnit.street)+" ")]),_c('p',{staticClass:"font-bold context"},[_vm._v(_vm._s(props.row.project.name))]),_c('p',{staticClass:"context"},[_vm._v(_vm._s(props.row.projectUnitType.name))]),_c('p',{staticClass:"context"},[_vm._v(" "+_vm._s(props.row.projectUnitFloorPlan.name)+" ")])])])]):(props.column.field == 'buyerContact')?_c('span',{staticClass:"buyer-contact",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(_vm._s(props.row.projectPurchaseReservation.phoneContact)+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.projectPurchaseReservation.email)+" ")])]):(
            props.column.field == 'assignedAgent' &&
            !_vm._.isEmpty(props.row.agent)
          )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('p',[_vm._v(_vm._s(props.row.agent.name))]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(" "+_vm._s(_vm.$getUserPhoneNumber(props.row.agent.user))+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.agent.user.email)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no bookings"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1),_c('modal',{model:{value:(_vm.cancelBookingModal.isShown),callback:function ($$v) {_vm.$set(_vm.cancelBookingModal, "isShown", $$v)},expression:"cancelBookingModal.isShown"}},[_c('cancel-booking',{attrs:{"bookingId":_vm.cancelBookingModal.id},on:{"cancel":function($event){_vm.cancelBookingModal.isShown = false},"submit":_vm.cancelBooking}})],1),_c('modal',{model:{value:(_vm.rejectReasonModal.isShown),callback:function ($$v) {_vm.$set(_vm.rejectReasonModal, "isShown", $$v)},expression:"rejectReasonModal.isShown"}},[_c('reason',{attrs:{"type":_vm.projectBookingStatus.REJECTED,"bookingId":_vm.rejectReasonModal.id},on:{"cancel":function($event){_vm.rejectReasonModal.isShown = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }