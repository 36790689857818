<template>
  <div class="manage-project-booking__rejected">
    <div v-if="!_.isEmpty(API)">
      <rejected-booking-table
        :getBookingsAPI="API.getBookings"
        :cancelBookingAPI="API.cancelBooking"
        :detailRouteName="getDetailRouteName()"
      ></rejected-booking-table>
    </div>
  </div>
</template>

<script>
import RejectedBookingTable from "@/components/GlobalComponents/Shared/ProjectSales/Booking/RejectedBookingTable";
import { projectBooking as bookingAPI } from "@/api";

export default {
  components: { RejectedBookingTable },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = bookingAPI.getProjectBookingAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectBookingDetail"
        : "ManageProjectBookingDetail";
    }
  }
};
</script>

<style lang="scss">
</style>
